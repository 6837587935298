import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Logo1 from '../assets/zeron.png'; 
import Logo2 from '../assets/luxon.png'; 

const Partnership = () => {
  return (
    <div>
        <h2 className='haduagaleri'>Partnership</h2>
    <Container className="py-3 parnership">
      <Row className="justify-content-center">
        <Col md={6} className="text-center">
          <img src={Logo1} alt="Logo 1" className="img-fluid" />
        </Col>
        <Col md={6} className="text-center">
          <img src={Logo2} alt="Logo 2" className="img-fluid" />
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default Partnership;
