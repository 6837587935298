import Navbarel from "../components/Navbarel";
import Hompeg from "../components/Hompeg";
import Footer from "../components/Footer";
// import Services from "../components/Services";
import What from "../components/What";
import Serpis from "../components/Serpis";
import Ourgallery from "../components/ourgallery";
import Partnership from "../components/partnership";
// import Jeda from "../components/jeda";
// import Navbareltri from "../components/Navbareltri";

const Home = () => {
  return (
    <div className="App">
      <Navbarel />
      <Hompeg />
      <Serpis />
      <What />
      <Ourgallery />
      <Partnership />
      <Footer />
    </div>
  );
};

export default Home;
