import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";

const Faq = () => {
  return (
    <div className="faq min-vh-100 w-90" id="faq">
      <Container>
        <Row className="mb-5">
          <Col>
            <h2 className="text-center fw-bold" data-aos="fade-up">
              Pertanyaan yang sering ditanyakan
            </h2>
            {/* <p className="text-center" data-aos="fade-up" data-aos-delay="100">
              Kami akan mendengarkan dengan seksama kebutuhan Anda dan bekerja sama dengan Anda untuk mengembangkan solusi yang sesuai dengan visi dan tujuan perusahaan Anda.
            </p> */}
          </Col>
        </Row>
        <Row className="row-cols-lg-2 row-cols-md-2 row-cols-1 g-4">
          <Col data-aos="fade-up" data-aos-delay="200">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Apa saja layanan yang ditawarkan oleh Gigateknik Indonesia?</Accordion.Header>
                <Accordion.Body className="textel-right">
                  Gigateknik Indonesia menyediakan berbagai layanan di bidang mekanikal, elektrikal, dan IT. Kami melayani instalasi, perawatan, perbaikan, dan solusi teknologi informasi. Layanan kami meliputi peralatan mekanikal, sistem
                  elektrikal, dan kebutuhan teknologi informasi perusahaan Anda.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col data-aos="fade-up" data-aos-delay="300">
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Berapa lama waktu yang diperlukan untuk menyelesaikan proyek mekanikal, elektrikal, atau IT?</Accordion.Header>
                <Accordion.Body className="textel-right">
                  Waktu penyelesaian proyek mekanikal, elektrikal, atau IT dapat bervariasi tergantung pada kompleksitas dan skala proyek tersebut. Kami akan melakukan penilaian awal untuk memberikan estimasi waktu yang tepat. Komitmen kami
                  adalah menyelesaikan setiap proyek sesuai dengan jadwal yang disepakati dengan pelanggan.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col data-aos="fade-up" data-aos-delay="400">
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Bagaimana Gigateknik Indonesia memastikan kualitas layanan yang diberikan?</Accordion.Header>
                <Accordion.Body className="textel-right">
                  Kami memiliki tim ahli yang berpengalaman di bidang mekanikal, elektrikal, dan IT. Kami mengutamakan kualitas dengan melakukan pemilihan bahan dan peralatan terbaik serta menerapkan standar dan prosedur yang ketat dalam
                  setiap tahap proyek. Kami juga melakukan pengujian dan pemeriksaan kualitas untuk memastikan kepuasan pelanggan.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col data-aos="fade-up" data-aos-delay="500">
            <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Apakah Gigateknik Indonesia menerima proyek di luar kota atau di luar negeri?</Accordion.Header>
                <Accordion.Body className="textel-right">
                  Ya, Gigateknik Indonesia menerima proyek baik di dalam maupun di luar kota, serta proyek internasional. Kami memiliki kemampuan dan pengalaman dalam menangani proyek di berbagai lokasi. Silakan hubungi kami untuk informasi
                  lebih lanjut mengenai proyek di luar kota atau di luar negeri.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col data-aos="fade-up" data-aos-delay="600">
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Bagaimana cara menghubungi tim dukungan pelanggan Gigateknik Indonesia?</Accordion.Header>
                <Accordion.Body className="textel-right">
                  Anda dapat menghubungi tim dukungan pelanggan kami melalui formulir kontak yang tersedia di situs web kami. Anda juga dapat mengirim email ke alamat admin@gigateknikindonesia.com atau menghubungi nomor Whatsapp
                  +6285173091150. Tim dukungan pelanggan kami siap membantu Anda dengan pertanyaan atau permintaan Anda.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col data-aos="fade-up" data-aos-delay="700">
            <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Apakah Gigateknik Indonesia memiliki sertifikasi atau pengakuan di bidang mekanikal, elektrikal, dan IT?</Accordion.Header>
                <Accordion.Body className="textel-right">
                  Ya, Gigateknik Indonesia memiliki sertifikasi dan pengakuan di bidang mekanikal, elektrikal, dan IT. Tim kami terdiri dari profesional yang memiliki kualifikasi dan sertifikasi yang relevan. Kami terus mengikuti
                  perkembangan terbaru di industri ini dan memastikan keahlian kami selaras dengan standar industri yang berlaku.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Faq;
