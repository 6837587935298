import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import logogigateknik from "../assets/Gallery/logo-gigateknik.png";

const Footer = () => {
  return (
    <div className="footer pb-3 pt-4">
      <Container className="mt-5">
        <Row>
          <Col className="text-start">
            {/* <img src={logogigateknik} alt="logo" className="logo" /> */}
            <h2 className="fw-bold">
              <span className="text-danger">GIGA Teknik</span> <span className="text-white">Indonesia</span>
            </h2>
            {/* <p className="text-white">
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters
            </p> */}
            <p className="text-white">
              <i className="fa-solid fa-location-dot"></i>&nbsp; Jl. Jeruk 3 No.113 RT 007 RW 001 ,Kel. Depok Jaya, Kec. Pancoran Mas, Kota Depok, Jawa Barat 16432.
            </p>
          </Col>
          {/* <Col className="text-end">
            <i className="fa-brands fa-facebook text-white fs-1 mx-lg-3 `mx-2"></i>
            <i className="fa-brands fa-twitter text-white fs-1 mx-lg-3 mx-2"></i>
            <i className="fa-brands fa-linkedin text-white fs-1 mx-lg-3 mx-2"></i>
          </Col> */}
          {/* <Col>

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.9707662515207!2d106.8130513958781!3d-6.397768771527342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e95ebc6ecc21%3A0x2eeac6c104608600!2sJl.%20Jeruk%203%20No.113%2C%20Depok%20Jaya%2C%20Kec.%20Pancoran%20Mas%2C%20Kota%20Depok%2C%20Jawa%20Barat%2016432!5e0!3m2!1sid!2sid!4v1698162659213!5m2!1sid!2sid" width="300" height="300"  allowFullScreen="" loading="lazy" ></iframe>

          </Col> */}
        </Row>
        <Row>
          <Col>
            <p className="text-center text-white-50">&copy; Giga Teknik Indonesia 2023</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
