import ele1 from "../../assets/Projects/ele/ele1.jpg";
import ele2 from "../../assets/Projects/ele/ele2.jpg";
import ele3 from "../../assets/Projects/ele/ele3.jpg";
import mecha1 from "../../assets/Projects/mecha/mecha1.jpg";
import mecha2 from "../../assets/Projects/mecha/mecha2.jpg";
import mecha3 from "../../assets/Projects/mecha/mecha3.jpg";
import aiti1 from "../../assets/Projects/aiti/aiti1.jpg";
import aiti2 from "../../assets/Projects/aiti/aiti2.jpg";

const Banyak = [
  {
    id: 1,
    image: ele1,
    name: "test1",
    category: "Electrical",
    desc: "Electrical enginering di Tangerang",
  },

  {
    id: 2,
    image: aiti1,
    name: "test2",
    category: "IT Services",
    desc: "Service IT di Tangerang",
  },

  {
    id: 3,
    image: mecha1,
    name: "test3",
    category: "Mechanical",
    desc: "Mechanical enginering di Tangerang",
  },

  {
    id: 4,
    image: ele2,
    name: "test4",
    category: "Electrical",
    desc: "Electrical enginering di Bali",
  },

  {
    id: 5,
    image: mecha2,
    name: "test3",
    category: "Mechanical",
    desc: "Mechanical enginering di Jakarta",
  },

  {
    id: 6,
    image: ele3,
    name: "test4",
    category: "Electrical",
    desc: "Electrical enginering di Jakarta",
  },

  {
    id: 7,
    image: mecha3,
    name: "test4",
    category: "Mechanical",
    desc: "Mechanical enginering di Jakarta",
  },

  {
    id: 8,
    image: aiti2,
    name: "test4",
    category: "IT Services",
    desc: "IT enginering di Jakarta",
  },
];

export default Banyak;
