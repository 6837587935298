import React from 'react';
import { Container} from 'react-bootstrap';

const Projects = () => {
  return (
    <div className="header-parallax">
      <Container  className='text-start'>
        <h1>Terminal 3</h1>
        <h2>Bandara Internasional Soekarno-Hatta</h2>
      </Container>
    </div>
  );
};

export default Projects;
