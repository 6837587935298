import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logogigateknik from "../assets/Gallery/logo-gigateknik.png";

const Navbarel = () => {
  const [changeColor, setChangeColor] = useState(false);
  const changeBackgroundColor = () => {
    if (window.scrollY === 0) {
      setChangeColor(true);
    } else if (window.scrollY <= 600) {
      setChangeColor(true);
    } else {
      setChangeColor(true);
    }
  };

  useEffect(() => {
    changeBackgroundColor();
    window.addEventListener("scroll", changeBackgroundColor);

    return () => {
      window.removeEventListener("scroll", changeBackgroundColor);
    };
  }, []);

  return (
    <div className="sticky-top">
      <Navbar variant="dark" expand="lg" className={changeColor ? "color-active shadow" : "shadow-none"}>
        <Container>
          <Navbar.Brand href="/" className="fw-bold fs-4">
            <div className="logo-wrapper">
              <img src={logogigateknik} alt="logo" className="logo" />
              {/* Giga Teknik Indonesia */}
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto text-center" variant="pills">
              <NavLink exact="true" to="/" className="mx-4 nav-link active-nav-link" onClick={() => setChangeColor()}>
                Beranda
              </NavLink>
              <NavLink exact="true" to="/Projs" className="mx-4 nav-link active-nav-link" onClick={() => setChangeColor()}>
                Projek
              </NavLink>
              <NavLink exact="true" to="/Blogging" className="mx-4 nav-link active-nav-link" onClick={() => setChangeColor()}>
                Tentang Kami
              </NavLink>
              <NavLink exact="true" to="/Faq" className="mx-4 nav-link active-nav-link" onClick={() => setChangeColor()}>
                Faq
              </NavLink>
            </Nav>
            <Nav className="ms-auto text-center" variant="pills">
              <div className="social-links">
                <div id="whatsapp" className="social-btn flex-center">
                  <a href="https://api.whatsapp.com/send?phone=6285173091150" target="blank" title="Kirim pesan WhatsApp ke nomor ini">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="24" width="24">
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                  </a>
                  <span>085173091150</span>
                </div>

                <div id="phone" className="social-btn flex-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                  </svg>
                  <span>(021)-39700534</span>
                </div>

                <a id="email" className="social-btn flex-center" href="mailto:admin@gigateknikindonesia.com">
                  <svg viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.6,3.4H2.4C1.1,3.4,0,4.5,0,5.8v12.4c0,1.3,1.1,2.4,2.4,2.4h19.2c1.3,0,2.4-1.1,2.4-2.4V5.8C24,4.5,22.9,3.4,21.6,3.4zM22.6,18.2c0,0.6-0.5,1-1,1H2.4c-0.6,0-1-0.5-1-1V5.8c0-0.6,0.5-1,1-1h19.2c0.6,0,1,0.5,1,1V18.2z M20,7.8L12,13.3L4,7.8V6.6L12,12.1l8-5.5V7.8z"></path>
                  </svg>
                  <span>admin@gigateknikindonesia.com</span>
                </a>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navbarel;
