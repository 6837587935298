import React, { useState, useRef, useEffect } from "react";
import vidd from "../assets/Gallery/VIDDE.mp4";
import Pic1 from "../assets/hero.jpg";
import { Container, Row, Col } from "react-bootstrap";

const Hompeg = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <div className="Hompeg" id="home">
      <Container className="kontenneh">
        <Row>
          <Col>
            <h1 className="hasatuhompeg text-white text-center fs-1 animate__animated animate__fadeInUp">SOLUSI DUNIA BISNIS</h1>
            <p className="text-white-50 animate__animated animate__fadeInUp animate__delay-1s">Menjadi kebanggaan kami memberikan solusi terbaik dan pelayanan berkualitas kepada pelanggan kami.</p>
          </Col>
        </Row>
      </Container>
      <video className="empid" ref={videoRef} src={vidd} type="video/mp4" autoPlay loop muted playsInline />
      <div className="contvid" style={{ backgroundImage: `url(${Pic1})`, display: !isPlaying ? "block" : "none" }}>
        <Container className="kontennehisi">
          <Row>
            <Col>
              <h1 className="hasatuhompeg text-white text-center fs-1 animate__animated animate__fadeInUp">SOLUSI DUNIA BISNIS</h1>
              <p className="text-white-50 animate__animated animate__fadeInUp animate__delay-1s">Menjadi kebanggaan kami memberikan solusi terbaik dan pelayanan berkualitas kepada pelanggan kami.</p>
            </Col>
          </Row>
        </Container>
      </div>

      <button className="buttone" onClick={handleTogglePlay}>
        {isPlaying ? (
          <svg viewBox="0 0 15 17.5" height="50" width="30" fill="white" xmlns="http://www.w3.org/2000/svg" className="icon">
            <rect x="4.5" y="3.5" width="10" height="10.5"></rect>
          </svg>
        ) : (
          <svg viewBox="0 0 15 17.5" height="50" width="50" xmlns="http://www.w3.org/2000/svg" className="icon">
            <path d="M5 3.5V15.5L14 9.5L5 3.5ZM6.5 12V7L11 9.5L6.5 12Z" />
          </svg>
        )}
      </button>
    </div>
  );
};

export default Hompeg;
