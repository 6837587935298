import React from 'react';
import { Link } from 'react-router-dom';

const Allprojects = () => {
  return (
    <div className='projek'>
      <h1 className='haduagaleri'>Projek Kami:</h1>
      <ul className='text-start'>
        <li className='haduagaleri'>
          <Link to="/Projs/project-bsh">Terminal 3 Bandara Soekarno-Hatta</Link>
        </li>
        <li>-</li>
        <li>-</li>
      </ul>
    </div>
  );
};

export default Allprojects;
