import { useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import Banyak from "./BACK/Banyak";

function Ourgallery() {
  const items = Banyak.map((elem) => {
    return {
      image: elem.image,
      thumbnail: elem.thumbnail,
      caption: elem.name,
    };
  });

  const slideRef = useRef(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, //atur interval slide menjadi 3 detik
    beforeChange: (current, next) => (slideRef.current = next),
  };

  return (
    <div className="Ourgallery min-vh-100" data-aos="zoom-in" id="gallery">
      <Container>
        <Row className="mb-5 mt-5">
          <Col className="slidercol">
            <div className="sliderour">
              <Slider {...settings}>
                {items.map((item, index) => {
                  return (
                    <div key={index}>
                      <img src={item.image} alt={item.caption} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </Col>

          <Col className="ingponyaour">
            <div>
              <h2 className="haduagaleri">Projek Kami</h2>
              <p className="lead">
                Kepuasan pelanggan adalah prioritas utama kami. Kami selalu berusaha untuk memberikan pelayanan terbaik dan menjamin kepuasan Anda. Kami mengutamakan kualitas, keandalan, dan keamanan dalam setiap proyek yang kami tangani.
              </p>
              <Button className="btn-danger animate__animated animate__fadeInUp animate__delay-1s" variant="primary" href="/Projs">
                Selengkapnya
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Ourgallery;
