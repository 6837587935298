import Blog from "../components/Blog";
import Footer from "../components/Footer";
import Navbarel from "../components/Navbarel";

const Blogging = () => {
  return (
    <div className="App">
      <Navbarel />
      <Blog />
      <Footer />
    </div>
  );
};

export default Blogging;
