import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Us from "../assets/Gallery/collab.jpg";

function What() {
  return (
    <div className="wuutt">
      <Container className=" my-5">
        <Row>
          <Col md={6} className="text-start">
            <h2 className="haduanyawhat">Giga Teknik Indonesia</h2>
            <p className="lead">
              Misi kami adalah menjadi mitra yang andal dan terpercaya dalam memenuhi kebutuhan Anda di bidang mekanikal, elektrikal, dan IT. Kami berkomitmen untuk memberikan layanan yang profesional, efisien, dan tepat waktu.
            </p>
            <Button className="btn-danger animate__animated animate__fadeInUp animate__delay-1s" variant="primary" href="/Blogging">
              Selengkapnya
            </Button>
          </Col>
          <Col>
            <div className="kelompoknyawhatimg">
              <img src={Us} className="whatimg" alt="collab" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default What;
