import Slider from 'react-slick';
import cardsData from './BACK/Banyak';
import { Col, Card } from 'react-bootstrap';


const CarouselPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className='Slaide'>
    <Slider {...settings}>
      {cardsData.map((card, index) => (
        <Col key={index} sm={6} md={4} lg={3}>
        <Card className='cardeltu' style={{ marginBottom: '20px' }}>
          <Card.Img variant="top" src={card.image} alt={card.name} />
          <Card.Body>
            <Card.Title><h3>{card.name}</h3></Card.Title>
            <Card.Text>{card.desc}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      ))}
    </Slider>
    </div>
  );
};

export default CarouselPage;
