import Allprojects from "../components/Allprojects";
import Footer from "../components/Footer";
// import Gambarcard from "../components/Gambarcard";
// import Muchgallery from "../components/Muchgallery";
import Navbarel from "../components/Navbarel";
// import Ourgallery from "../components/ourgallery";

const Media = () => {
  return (
    <div className="App">
      <Navbarel />
      <Allprojects />
      {/* <Projects /> */}
      {/* <CarouselPage /> */}
      {/* <ProjectDescriptionPage /> */}
      {/* <Muchgallery /> */}
      <Footer />
    </div>
  );
};

export default Media;
