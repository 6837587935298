import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
// import { ReactComponent as Award } from "../icons/award-solid.svg";
// import Ikons from "../assets/Ikons";
import Ikonsele from "../assets/ele.png";
import Ikonsmecha from "../assets/mecha.png";
import Ikonsaiti from "../assets/aiti.png";
import Ikonstrade from "../assets/trade.png";
const Serpis = () => {
  return (
    <div className="serpis min-vh-100 d-flex align-items-center" id="services">
      <Container className="containerel">
        <Row className="mb-5">
          <Col>
            <h1 className="text-center fw-bold" data-aos="fade-up">
              Pelayanan Kami
            </h1>
            <p className="text-center text-black-50" data-aos="fade-up" data-aos-delay="100">
              Kami adalah perusahaan yang bergerak di bidang mekanikal, elektrikal, dan layanan IT. Dengan tim ahli yang berpengalaman, kami menyediakan berbagai layanan yang meliputi instalasi peralatan mekanikal dan elektrikal, perawatan
              dan perbaikan, serta solusi teknologi informasi yang inovatif. Kami berdedikasi untuk membantu Anda mencapai tujuan bisnis Anda dengan memberikan solusi yang sesuai dengan kebutuhan unik perusahaan Anda.
            </p>
          </Col>

          <div className="kumpulkartu">
  <Row className="row-cols-lg-4 row-cols-md-2 row-cols-1 mb-5 justify-content-center">
    <Col className=" p-5 px-3" data-aos="fade-up" data-aos-delay="200">
      <Card className="cardel">
        <Card.Body className="cardel-img">
          <img src={Ikonsele} className="imgel" alt="ele" />
          <div className="cardel-info">
            <Card.Title className="text-title">Electrical</Card.Title>
          </div>
        </Card.Body>
      </Card>
    </Col>

    <Col className=" p-5 px-3" data-aos="fade-up" data-aos-delay="300">
      <Card className="cardelwa">
        <Card.Body className="cardel-img">
          <img src={Ikonsmecha} className="imgel" alt="mecha" />
          <div className="cardel-info">
            <Card.Title className="text-title">Mechanical</Card.Title>
          </div>
        </Card.Body>
      </Card>
    </Col>

    <Col className="p-5 px-3" data-aos="fade-up" data-aos-delay="400">
      <Card className="cardelga">
        <Card.Body className="cardel-img">
          <img src={Ikonsaiti} className="imgel" alt="aiti" />
          <div className="cardel-info">
            <Card.Title className="text-title">IT Services</Card.Title>
          </div>
        </Card.Body>
      </Card>
    </Col>

    <Col className=" p-5 px-3" data-aos="fade-up" data-aos-delay="300">
      <Card className="cardelpat">
        <Card.Body className="cardel-img">
          <img src={Ikonstrade} className="imgel" alt="aiti" />
          <div className="cardel-info">
            <Card.Title className="text-title">Trading Services</Card.Title>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>
</div>

        </Row>
      </Container>
    </div>
  );
};

export default Serpis;
