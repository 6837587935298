import React from "react";
import {Button } from "react-bootstrap";

const Notfound = () => {
  return (

    <div class="flex-container">
  <div class="text-center">
    <h1>
      <span class="fade-in" id="digit1">4</span>
      <span class="fade-in" id="digit2">0</span>
      <span class="fade-in" id="digit3">4</span>
    </h1>
    <h3 class="fadeIn">Halaman Tidak Ditemukan</h3>
    <Button variant="primary" href="/">
            Kembali Ke Beranda
          </Button>
  </div>
</div>
  );
};

export default Notfound;
