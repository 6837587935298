// import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import Home from "./Pages/Home";
import Blogging from "./Pages/Blogging";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Notfound from "./Pages/Notfound";
import Kmunnya from "./Pages/Kmunnya";
import Media from "./Pages/Media";
import BSH from "./Pages/Project-bsh";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Faq" element={<Kmunnya />} />
        <Route path="/Projs" element={<Media />} />
        <Route path="/Projs/project-bsh" element={<BSH />} />
        <Route path="/Blogging" element={<Blogging />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </Router>
  );
};

export default App;
