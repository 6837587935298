import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ProjectDescriptionPage = () => {
  return (
    <Container className="py-5">
      <h3 className="text-start mb-4">Project Description</h3>
      <Row>
        <Col md={12}>
          <p className="text-start">
            <strong>Lorem Ipsum 1:</strong> Lorem ipsum dolor sit amet.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <p className="text-start">
            <strong>Lorem Ipsum 2:</strong> Lorem ipsum.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <p className="text-start">
            <strong>Lorem Ipsum 3:</strong> 23
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectDescriptionPage;
