import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Navbarel from "../components/Navbarel";
// import Navbareltu from "../components/Navbareltu";

const Kmunnya = () => {
  return (
    <div className="App">
      {/* <Navbareltu /> */}
      <Navbarel />
      <Faq />
      <Footer />
    </div>
  );
};

export default Kmunnya;
