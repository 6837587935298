import React from "react";
import { Container, Row, Col, Image, Card, Button } from "react-bootstrap";
import Banner from "../assets/banneer.png";

const Blog = () => {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <Image src={Banner} fluid />
        </Col>
      </Row>
      <Row className="my-5">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Card.Title className="textel-right">Tentang Giga Teknik Indoensia</Card.Title>
              <Card.Text className="textel-right">
            
                  Kami berkomitmen untuk memberikan layanan yang profesional, efisien, dan tepat waktu. Dengan tim ahli yang berpengalaman, kami menyediakan berbagai layanan yang meliputi instalasi peralatan mekanikal dan elektrikal,
                  perawatan dan perbaikan, serta solusi teknologi informasi yang inovatif. Kami berdedikasi untuk membantu Anda mencapai tujuan bisnis Anda dengan memberikan solusi yang sesuai dengan kebutuhan unik perusahaan Anda. Kepuasan
                  pelanggan adalah prioritas utama kami.
                </Card.Text>
              <Card.Text className="textel-right">
                  Kami selalu berusaha untuk memberikan pelayanan terbaik dan menjamin kepuasan Anda. Kami mengutamakan kualitas, keandalan, dan keamanan dalam setiap proyek yang kami tangani. Kami percaya bahwa kolaborasi yang baik dengan
                  pelanggan adalah kunci keberhasilan. Kami akan mendengarkan dengan seksama kebutuhan Anda dan bekerja sama dengan Anda untuk mengembangkan solusi yang sesuai dengan visi dan tujuan perusahaan Anda. Jika Anda mencari
                  partner yang handal untuk kebutuhan mekanikal, elektrikal, dan IT, Giga Teknik Indonesia siap membantu. Hubungi kami hari ini untuk mendiskusikan bagaimana kami dapat membantu Anda mencapai keberhasilan bisnis Anda.
              
              </Card.Text>
              <Card.Text className="textel-right">
  <strong>Visi:</strong>
</Card.Text>
<ul>
  <Card.Text className="textel-right">
    <li>Memberikan pelayanan berkualitas di bidang mekanikal, elektrikal & IT Services.</li>
    <li>Mengambil bagian dalam pembangunan dan pengembangan aset bangsa menuju bangsa yang memiliki SDM dengan produktivitas tinggi demi mengisi kebutuhan usaha domestik maupun internasional.</li>
  </Card.Text>
</ul>
<Card.Text className="textel-right">
  <strong>Misi:</strong>
</Card.Text>
<ul>
  <Card.Text className="textel-right">
    <li>Menjadi perusahaan jasa mekanikal, elektrikal & IT Services yang kompeten, profesional, dan dapat dipercaya.</li>
    <li>Menjadi perusahaan yang fokus pada mekanikal, elektrikal & IT Services yang mampu berdaya saing global dan mendapat apresiasi secara internasional.</li>
    <li>Berkomitmen memberikan kepuasan pelanggan dengan menjaga kualitas, harga, dan waktu pengerjaan.</li>
    <li>Memberikan kesempatan lapangan pekerjaan pada SDM potensial.</li>
  </Card.Text>
</ul>

            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <Button variant="primary" href="/">
            Beranda
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Blog;
