import CarouselPage from "../components/CarouselPage"
import Footer from "../components/Footer"
import Navbarel from "../components/Navbarel"
import ProjectDescriptionPage from "../components/ProjectDescriptionPage"
import Projects from "../components/project"

const BSH = () => {
    return (
        <div className='BSH'>
            <Navbarel />
            <Projects />
            <CarouselPage />
            <ProjectDescriptionPage />
            <Footer />
        </div>
)
}
export default BSH